import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import WelcomeUserRankItem, {
    IWelcomeUserRankItemProps,
} from '@feature/others/pages/welcome/WelcomeUserRankItem/WelcomeUserRankItem'
import Rank1Thumbnail from '@image/landing/img_welcome_user_rank1.jpg'
import Rank1FlagSource from '@image/landing/img_welcome_user_rank_flag1.jpg'
import Rank1ChartSource from '@image/token/img_token_user_rank_chart1.png'
import Rank2Thumbnail from '@image/landing/img_welcome_user_rank2.jpg'
import Rank2FlagSource from '@image/landing/img_welcome_user_rank_flag2.jpg'
import Rank2ChartSource from '@image/token/img_token_user_rank_chart2.png'
import Rank3Thumbnail from '@image/landing/img_welcome_user_rank3.jpg'
import Rank3FlagSource from '@image/landing/img_welcome_user_rank_flag3.jpg'
import Rank3ChartSource from '@image/token/img_token_user_rank_chart3.png'
import Rank4Thumbnail from '@image/landing/img_welcome_user_rank4.jpg'
import Rank4FlagSource from '@image/landing/img_welcome_user_rank_flag4.jpg'
import Rank4ChartSource from '@image/token/img_token_user_rank_chart4.png'
import Rank5Thumbnail from '@image/landing/img_welcome_user_rank5.jpg'
import Rank5FlagSource from '@image/landing/img_welcome_user_rank_flag5.jpg'
import Rank5ChartSource from '@image/token/img_token_user_rank_chart5.png'
import Rank6Thumbnail from '@image/landing/img_welcome_user_rank6.jpg'
import Rank6FlagSource from '@image/landing/img_welcome_user_rank_flag6.jpg'
import Rank6ChartSource from '@image/token/img_token_user_rank_chart6.png'

import MobileBgSource from '@image/token/img_token_league_feature_mobile_bg.png'
import WebBgSource from '@image/token/img_token_league_feature_web_bg.png'
import useWindowSize from '@hook/useWindowSize'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useIsVisible} from '@hook/useIsVisible'
import TokenServiceUserRankItem from '@feature/landing/pages/token/containers/service/items/TokenServiceUserRankItem'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
}

const userRankList: IWelcomeUserRankItemProps[] = [
    {
        rank: 1,
        name: 'super',
        thumbnail: Rank1Thumbnail,
        flag: Rank1FlagSource,
        exchanges: ['binance', 'bithumb', 'bitmex'],
        profitRate: 2600.35,
        chartImage: Rank1ChartSource,
        isSelected: true,
    },
    {
        rank: 2,
        name: 'bestcoin',
        thumbnail: Rank2Thumbnail,
        flag: Rank2FlagSource,
        exchanges: ['bybit'],
        profitRate: 1127.86,
        chartImage: Rank2ChartSource,
        isSelected: false,
    },
    {
        rank: 3,
        name: 'happyday',
        thumbnail: Rank3Thumbnail,
        flag: Rank3FlagSource,
        exchanges: ['binance', 'bitmex', 'phemex'],
        profitRate: 1088.32,
        chartImage: Rank3ChartSource,
        isSelected: false,
    },
    {
        rank: 4,
        name: 'levelup',
        thumbnail: Rank4Thumbnail,
        flag: Rank4FlagSource,
        exchanges: ['upbit'],
        profitRate: 976.77,
        chartImage: Rank4ChartSource,
        isSelected: false,
    },
    {
        rank: 5,
        name: 'coincoin',
        thumbnail: Rank5Thumbnail,
        flag: Rank5FlagSource,
        exchanges: ['binance', 'bitmex'],
        profitRate: 587.82,
        chartImage: Rank5ChartSource,
        isSelected: false,
    },
    {
        rank: 6,
        name: 'superpower',
        thumbnail: Rank6Thumbnail,
        flag: Rank6FlagSource,
        exchanges: ['bitmex', 'bingx'],
        profitRate: 305.11,
        chartImage: Rank6ChartSource,
        isSelected: false,
    },
]
const TokenServiceLeagueFeatureContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    return (
        <div className={`layout-full_desktop max-w-[1280px] ${className}`}>
            <div
                className={
                    'flex flex-col items-center px-[20px] pt-[150px] pb-[200px] land:pt-[100px] land:pb-[150px] sm:py-[70px] land:px-[40px] md:px-[20px] space-y-[100px] md:space-y-[50px]'
                }>
                <div className={'space-y-[40px] md:space-y-[20px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                        <Text
                            className={
                                'text-white text-center text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.features.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE} delay={'delay-500'}>
                        <Text
                            className={
                                'text-[#C9CAC3] typo-landing_body_medium text-center md:typo-landing_body_caption'
                            }>
                            {t('landingTokenSale.TokenService.features.desc')}
                        </Text>
                    </FadeInView>
                </div>

                <div ref={fadeAnimationRef} className={'space-y-[15px] w-full max-w-[1116px] land:max-w-[780px]'}>
                    {userRankList?.map(item => (
                        <TokenServiceUserRankItem key={item?.rank} {...item} />
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TokenServiceLeagueFeatureContainer
