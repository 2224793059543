import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import FeeDiscountIcon from '@svg/token/icon_token_util_fee_discounts.svg'
import PremiumServicesIcon from '@svg/token/icon_token_util_premium_services.svg'
import StreamingEventsIcon from '@svg/token/icon_token_util_streaming_events.svg'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useIsVisible} from '@hook/useIsVisible'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
}

const TokenInfoUtilitiesContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    return (
        <div className={`bg-[#1F1F26] ${className}`}>
            <div
                className={
                    'layout-full_desktop max-w-[1280px] px-[20px] py-[200px] land:py-[150px] md:py-[100px] sm:py-[70px] land:px-[40px] md:px-[20px] space-y-[100px] md:space-y-[50px]'
                }>
                <div className={'space-y-[40px] sm:space-y-[20px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                        <Text
                            className={
                                'text-white text-center text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.token.utilities.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-300'}>
                        <Text
                            className={
                                'text-[#C9CAC3] text-center text-landing_body_medium md:text-landing_body_caption !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.token.utilities.desc')}
                        </Text>
                    </FadeInView>
                </div>
                <div ref={fadeAnimationRef} className={'flex items-start gap-x-[40px] sm:flex-col'}>
                    <div
                        className={
                            'w-full flex flex-col items-center justify-center pb-[30px] gap-y-[40px] md:gap-y-[10px]'
                        }>
                        <FeeDiscountIcon className={'w-[120px] h-[120px] md:w-[70px] md:h-[70px]'} />
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_large font-semibold md:text-landing_body_small md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.fee.discount.title')}
                            </Text>
                        </FadeInView>
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_medium md:text-landing_body_caption !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.fee.discount.desc')}
                            </Text>
                        </FadeInView>
                    </div>
                    <div
                        className={
                            'w-full flex flex-col items-center justify-center pb-[30px] gap-y-[40px] md:gap-y-[10px]'
                        }>
                        <PremiumServicesIcon className={'w-[120px] h-[120px] md:w-[70px] md:h-[70px]'} />
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-700'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_large font-semibold md:text-landing_body_small md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.premium.service.title')}
                            </Text>
                        </FadeInView>
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-700'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_medium md:text-landing_body_caption !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.premium.service.desc')}
                            </Text>
                        </FadeInView>
                    </div>
                    <div
                        className={
                            'w-full flex flex-col items-center justify-center pb-[30px] gap-y-[40px] md:gap-y-[10px]'
                        }>
                        <StreamingEventsIcon className={'w-[120px] h-[120px] md:w-[70px] md:h-[70px]'} />
                        <FadeInView
                            isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}
                            delay={'delay-[900ms]'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_large font-semibold md:text-landing_body_small md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.streaming.events.title')}
                            </Text>
                        </FadeInView>
                        <FadeInView
                            isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}
                            delay={'delay-[900ms]'}>
                            <Text
                                className={
                                    'text-center text-white text-landing_body_medium md:text-landing_body_caption !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.util.streaming.events.desc')}
                            </Text>
                        </FadeInView>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TokenInfoUtilitiesContainer
