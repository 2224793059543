import React from 'react'

import {useTranslation} from 'next-i18next'

import classNames from 'classnames'

import Text from '@component/Text/Text'
import BorderGradientView from '@feature/landing/pages/token/components/BorderGradientView'

import TokenIcon from '@svg/token/icon_lot_token_symbol.svg'
import TokenIconWhite from '@svg/token/icon_lot_token_symbol_white.svg'
import ArrowTopRight from '@svg/token/icon_arrow_top_right.svg'
import Link from 'next/link'
import {getLinkUrl, URL_ACCOUNT_POINT} from '@constant/url'
import useLogin from '@hook/useLogin'
import {useLoginModalStore} from '@store/LoginModalStore'

interface IProps {
    className?: string
}

const TokenSaleBox: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {isLogin} = useLogin()
    return (
        <div
            className={classNames(
                'w-full max-w-[500px] land:min-w-full p-[30px] sm:p-[20px] land:py-[20px] md:py-[30px] space-y-[40px] land:space-y-[30px] sm:space-y-[20px] bg-[#FAFFC2]/10 border border-white/30 rounded-[10px] ',
                'shadow-[inset_0_4px_10px_0_rgba(255,255,255,0.1)] drop-shadow-[0_4px_4px_rgba(0,0,0,0.05)] backdrop-blur',
                className,
            )}>
            <div
                className={
                    'flex flex-col items-center justify-center gap-y-[10px] land:flex-row land:gap-x-[10px] land:justify-start'
                }>
                <TokenIcon className={'w-[60px] h-[60px] land:w-[30px] land:h-[30px]'} />
                <Text
                    className={classNames(
                        'text-landing_body_large font-semibold !font-landing',
                        'bg-gradient-token inline-block text-transparent bg-clip-text',
                    )}>
                    {t('landingTokenSale.TokenSaleBox.title')}
                </Text>
            </div>

            <div className={'space-y-[40px] land:flex land:gap-x-[40px] land:space-y-0 md:flex-col md:gap-y-[30px]'}>
                <div className={'w-full space-y-[7px]'}>
                    <Text
                        className={
                            'text-landing_body_small font-semibold text-white sm:text-landing_body_caption sm:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenSaleBox.buy.lot.title')}
                    </Text>
                    <div
                        className={
                            'flex items-center justify-center gap-x-[5px] bg-[#F5FAFF]/10 py-[20px] rounded-full'
                        }>
                        <TokenIconWhite />
                        <Text className={'typo-landing_body_small font-bold text-white text-center'}>
                            {t('landingTokenSale.TokenSaleBox.buy.lot.desc')}
                        </Text>
                    </div>
                </div>

                <div className={'w-full'}>
                    <Text
                        className={
                            'text-landing_body_small font-semibold text-white mb-[7px] sm:text-landing_body_caption sm:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenSaleBox.earn.point.title')}
                    </Text>

                    <Link
                        data-cy={'tokenLanding_url_pointPage'}
                        onClick={e => {
                            if (!isLogin()) {
                                showLoginModal()
                                if (e?.cancelable) {
                                    e?.preventDefault()
                                }
                                e?.stopPropagation()
                            }
                        }}
                        href={getLinkUrl(URL_ACCOUNT_POINT)}>
                        <div
                            className={
                                'relative flex items-center justify-center gap-x-[10px] py-[20px] sm:py-0 sm:px-[40px] sm:h-[62px]  rounded-full hover:bg-gradient-to-r hover:from-[#BDFF0010] hover:to-transparent active:from-transparent'
                            }>
                            <Text
                                className={
                                    'typo-landing_body_small text-center bg-gradient-token inline-block text-transparent bg-clip-text sm:text-left'
                                }>
                                {t('landingTokenSale.TokenSaleBox.earn.point.desc')}
                            </Text>
                            <div>
                                <ArrowTopRight />
                            </div>
                            <BorderGradientView className={'rounded-full bg-gradient-token'} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default TokenSaleBox
