import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'
import useWindowSize from '@hook/useWindowSize'
import useQueryGetAnnouncements from '@hook/query/announce/useQueryGetAnnouncements'
import {useIsVisible} from '@hook/useIsVisible'
import TokenLandingAnnounceBannerContainer from '@feature/landing/pages/token/containers/TokenLandingAnnounceBannerContainer'
import FadeInView from '@component/animation/fadeIn/FadeInView'

interface IProps {
    className?: string
}

const TokenLandingAnnounceContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {announcements} = useQueryGetAnnouncements(7)

    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    return (
        <section className={className}>
            <div
                className={
                    'w-full max-w-[1280px] mx-auto land:px-[40px] md:px-[20px] py-[200px] land:py-[150px] md:py-[70px]'
                }>
                <div ref={fadeAnimationRef} className={'flex items-center justify-between mb-[50px] sm:mb-[20px]'}>
                    <FadeInView isVisible={isVisible}>
                        <Text
                            className={
                                'text-white text-landing_heading2 md:text-landing_body_large text-center md:font-bold !font-landing'
                            }>
                            {t('landingWelcome.announcements.title')}
                        </Text>
                    </FadeInView>
                    <Link href={`/announce`}>
                        <div className={'flex items-center cursor-pointer md:hidden'}>
                            <Text className={'typo-landing_body_medium text-white underline'}>
                                {t('landingWelcome.announcements.view.more.button.text')}
                            </Text>
                        </div>
                    </Link>
                </div>

                <TokenLandingAnnounceBannerContainer data={announcements} />
                <Link href={`/announce`} className={'hidden md:flex'}>
                    <div className={'flex items-center justify-center py-[10px] cursor-pointer'}>
                        <Text className={'typo-landing_body_small text-white underline'}>
                            {t('landingWelcome.announcements.view.more.button.text')}
                        </Text>
                    </div>
                </Link>
            </div>
        </section>
    )
}
export default TokenLandingAnnounceContainer
